import React from 'react';
import { Link } from 'gatsby-plugin-react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHandDots,
  faPersonDotsFromLine,
  faRectangleList,
  faSyringe,
  faVial,
  faWind,
} from '@fortawesome/free-solid-svg-icons';
import useLocale from 'hooks/useLocale';
import faceLift from '../../assets/images/surgery-prices/face-lift.png';
import breastAugmentation from '../../assets/images/surgery-prices/Breast-Augmentation.png';
import rhinoplasty from '../../assets/images/surgery-prices/Rhinoplasty.png';
import liposuction from '../../assets/images/surgery-prices/liposuction.png';
import tummyTuck from '../../assets/images/surgery-prices/tummy-tuck.png';
import hairTransplant from '../../assets/images/surgery-prices/hair-transplant.png';

type ContactRowPropTypes = {};

const Treatments: React.FC<ContactRowPropTypes> = ({}) => {
  const SCOPE_OPTIONS = {
    scope: 'components.Treatments.labels',
  };
  const { t } = useLocale();

  return (
    <div className="mx-auto w-4/5 mx-auto font-poppins font-medium mb-5 text-primary">
      <ul className="grid grid-cols-12 w-full gap-8">
        <li className="col-span-10 lg:col-span-4 md:col-span-5">
          <Link
            to="/face-lift"
            className=" bg-[#e4edf4] text-center p-2 hover:bg-white hover:shadow-site block"
          >
            <img src={faceLift} width={80} className="m-auto mb-4" />
            <div className="text-lg">{t('fatInjection', SCOPE_OPTIONS)}</div>
          </Link>
        </li>

        <li className="col-span-10 lg:col-span-4 md:col-span-5">
          <Link
            to="/breast-augmentation-2"
            className=" bg-[#e4edf4] text-center p-2 hover:bg-white hover:shadow-site block"
          >
            <img src={breastAugmentation} width={80} className="m-auto mb-4" />
            <div className="text-lg">{t('mesotherapy', SCOPE_OPTIONS)}</div>
          </Link>
        </li>

        <li className="col-span-10 lg:col-span-4 md:col-span-5">
          <Link
            to="/rhinoplasty"
            className=" bg-[#e4edf4] text-center p-2 hover:bg-white hover:shadow-site block"
          >
            <img src={rhinoplasty} width={80} className="m-auto mb-4" />
            <div className="text-lg">{t('prp', SCOPE_OPTIONS)}</div>
          </Link>
        </li>

        <li className="col-span-10 lg:col-span-4 md:col-span-5">
          <Link
            to="/liposuction"
            className=" bg-[#e4edf4] text-center p-2 hover:bg-white hover:shadow-site block"
          >
            <img src={liposuction} width={80} className="m-auto mb-4" />
            <div className="text-lg">{t('skinSurgery', SCOPE_OPTIONS)}</div>
          </Link>
        </li>

        <li className="col-span-10 lg:col-span-4 md:col-span-5">
          <Link
            to="/abdominoplasty"
            className=" bg-[#e4edf4] text-center p-2 hover:bg-white hover:shadow-site block"
          >
            <img src={tummyTuck} width={80} className="m-auto mb-4" />
            <div className="text-lg">{t('tummyTuck', SCOPE_OPTIONS)}</div>
          </Link>
        </li>

        <li className="col-span-10 lg:col-span-4 md:col-span-5">
          <a
            href="https://hairmedicareturkey.com/en"
            target="blank"
            className=" bg-[#e4edf4] text-center p-2 hover:bg-white hover:shadow-site block"
          >
            <img src={hairTransplant} width={80} className="m-auto mb-4" />
            <div className="text-lg">{t('hairTransplant', SCOPE_OPTIONS)}</div>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Treatments;
