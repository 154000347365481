import React from 'react';
import { Link } from 'gatsby-plugin-react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEnvelope,
  faLocationDot,
  faPhone,
  faSquareCheck,
} from '@fortawesome/free-solid-svg-icons';
import CONTACT_INFO from 'constants/contactInfo';
import useLocale from 'hooks/useLocale';

import Operations from '../../assets/images/serdar-bora-bayraktaroglu.jpeg';

type ContactRowPropTypes = {};

const SbbPersonal: React.FC<ContactRowPropTypes> = ({}) => {
  const SCOPE_OPTIONS = {
    scope: 'components.SbbPersonal',
  };

  const { t, language } = useLocale();

  return (
    <div className="mb-8">
      <div className="w-4/5 mx-auto font-poppins font-primary">
        <div className="grid grid-cols-12">
          <div className="col-span-12 lg:col-span-8 pr-8">
            <h4 className="text-[26px] font-semibold uppercase">
              {t('labels.drName', SCOPE_OPTIONS)}
            </h4>
            <span className="text-base font-semibold mb-5 block">
              {t('labels.drTitle', SCOPE_OPTIONS)}
            </span>

            <div
              className="text-base text-[#444]"
              dangerouslySetInnerHTML={{ __html: t('labels.content', SCOPE_OPTIONS) }}
            ></div>

            <div className="p-5 text-lg bg-white shadow-boxOther mt-4">
              <div className="text-center">{t('labels.contactText', SCOPE_OPTIONS)}</div>
              <div className="text-center text-red-600 font-bold text-base mt-2">
                +90 532 500 05 80
              </div>
            </div>
          </div>

          <div className="col-span-12 hidden lg:col-span-4 lg:flex flex justify-end">
            <div className="block">
              <img src={Operations} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SbbPersonal;
