import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { Link } from 'gatsby-plugin-react-i18next';
import Slider from 'react-slick';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { navigate } from '@reach/router';

import { useLocale } from '@app/hooks';
import { Layout, Icons, Spinner, BlogCard, Seo, ContactRow } from '@app/components';

import aestheticSurgeryIcon from '@app/assets/images/aesthetic-surgery-icon.png';
import medicalAestheticIcon from '@app/assets/images/medical-aesthetic-icon.png';
import image1 from '@app/assets/images/for-united-kingdom-aestetic-surgery-in-turkey.jpeg';
import image2 from '@app/assets/images/germany-aestetic-surgery-in-turkey.jpeg';
import image3 from '@app/assets/images/for-belgium-aestetic-surgery-in-turkey.jpeg';
import image4 from '@app/assets/images/2-istanbul-for-aestetic-surgery-in-turkey.jpeg';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLocationDot, faPhone } from '@fortawesome/free-solid-svg-icons';
import '../styles/pages/homepage.scss';
import SbbPersonal from 'components/SbbPersonal';
import Treatments from 'components/Treatments';

type HomePropTypes = {
  data: {
    allMarkdownRemark: {
      edges: {
        node: {
          frontmatter: {
            title: string;
            path: string;
            cover: any;
            date: string;
          };
        };
      }[];
    };
  };
};

const Home: React.FC<HomePropTypes> = ({ data }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { allMarkdownRemark } = data;

  const SCOPE_OPTIONS = {
    scope: 'pages.index',
  };
  const { t, language } = useLocale();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleFormSubmit = async (datas: any) => {
    setIsLoading(true);

    const mutateData = {
      fullName: datas.name,
      email: datas.email,
      phone: datas.phone,
      subject: datas.subject,
      message: datas.message,
      website: 'clinicsbbinternational',
    };

    const formData = new FormData();
    formData.append('data', JSON.stringify(mutateData));

    await axios({
      method: 'POST',
      url: 'https://sbb-panel.herokuapp.com/contact-forms',
      data: formData,
    }).then(() => {
      navigate(`/${language}/thanks`);
      setIsLoading(false);
    });
  };

  return (
    <Layout>
      <Seo title={t('labels.metaTitle', SCOPE_OPTIONS)} />
      <div className="bg-[#fff]">
        <Slider className="h-[250px] md:h-[700px] overflow-hidden">
          {allMarkdownRemark.edges.map(({ node }) => (
            <Link to={`/blog/${node.frontmatter.path}`} className="relative h-[250px] md:h-[700px]">
              <img
                className="absolute"
                src={node.frontmatter.cover?.publicURL}
                alt={node.frontmatter.title}
              />
              <div className="overlay w-full h-full absolute bg-black/50"></div>
              <div className="absolute md:bottom-64 md:left-16 left-0 text-white md:px-32 px-24 bottom-16">
                <div className="md:text-[16px] text-[13px]">{t('labels.blog', SCOPE_OPTIONS)}</div>
                <div className="font-poppins md:text-[48px] text-[20px] font-bold">
                  {node.frontmatter.title}
                </div>
                <div className="">{node.frontmatter.date}</div>
              </div>
            </Link>
          ))}
        </Slider>

        <div className="hidden lg:block">
          <ContactRow />
        </div>

        <div className="mt-5 lg:mt-0">
          <SbbPersonal />
        </div>

        {language === 'en' && (
          <div className="mt-5 lg:mt-0">
            <Treatments />
          </div>
        )}

        <div className="w-4/5 mx-auto">
          <div className="grid md:grid-cols-3 gap-y-8 md:gap-y-0 md:gap-x-8">
            <div className="relative w-full h-[440px] bg-no-repeat bg-cover bg-[url('../assets/images/aesthetic-surgery.jpg')]">
              <div className="absolute bg-black w-full h-full top-0 left-0 opacity-60"></div>
              <Link
                to={`/${t('labels.aestheticSurgeryLink', SCOPE_OPTIONS)}`}
                className="absolute bottom-24 text-center w-full"
              >
                <img
                  src={aestheticSurgeryIcon}
                  className="text-8xl mb-8 text-white m-auto"
                  width={100}
                />
                <h3 className="text-lg font-bold text-white">
                  {t('labels.aestheticSurgery', SCOPE_OPTIONS)}
                </h3>
              </Link>
            </div>
            <div className="relative w-full h-[440px] bg-no-repeat bg-cover bg-[url('../assets/images/SBB-Int-Before-After.jpeg')]">
              <div className="absolute bg-black w-full h-full top-0 left-0 opacity-60"></div>
              <Link to="/gallery" className="absolute bottom-24 text-center w-full">
                <Icons.Heart className="text-8xl mb-8 text-white" />
                <h3 className="text-lg font-bold text-white">
                  {t('labels.beforeAfterGallery', SCOPE_OPTIONS)}
                </h3>
              </Link>
            </div>
            <div className="relative w-full h-[440px] bg-no-repeat bg-cover bg-[url('../assets/images/medical-aesthetic.jpg')]">
              <div className="absolute bg-black w-full h-full top-0 left-0 opacity-60"></div>
              <Link to={'/medical-aesthetic'} className="absolute bottom-24 text-center w-full">
                <img
                  src={medicalAestheticIcon}
                  className="text-8xl mb-8 text-white m-auto"
                  width={100}
                />
                <h3 className="text-lg font-bold text-white">
                  {t('labels.medicalAesthetic', SCOPE_OPTIONS)}
                </h3>
              </Link>
            </div>
          </div>

          <div className="mt-24 mb-24">
            <h2 className="text-center text-4xl font-bold">
              {t('labels.figuresAboutAesthetics', SCOPE_OPTIONS)}
            </h2>

            <div className="w-full flex flex-col md:table table-fixed my-24 md:my-48">
              <div className="relative md:table-cell text-center">
                <div className="relative w-full h-1 border-b border-dotted border-[#5f4b8b] m-0 hidden md:block" />
                <div className="md:absolute top-0 min-h-[90px] md:pt-[35px] mb-8 md:mb-0 pb-0 w-full">
                  <h4 className="text-[2rem] font-light mb-1">
                    {t('labels.statistic1Title', SCOPE_OPTIONS)}
                  </h4>
                  <div>{t('labels.statistic1Description', SCOPE_OPTIONS)}</div>
                </div>
              </div>
              <div className="relative md:table-cell text-center">
                <div className="relative w-full h-1 border-b border-dotted border-[#5f4b8b] m-0 hidden md:block" />
                <div className="md:absolute top-0 min-h-[90px] md:pb-[35px] mb-8 md:mb-0 w-full md:-translate-y-full">
                  <h4 className="text-[2rem] font-light mb-1">
                    {t('labels.statistic2Title', SCOPE_OPTIONS)}
                  </h4>
                  <div>{t('labels.statistic2Description', SCOPE_OPTIONS)}</div>
                </div>
              </div>
              <div className="relative md:table-cell text-center">
                <div className="relative w-full h-1 border-b border-dotted border-[#5f4b8b] m-0 hidden md:block" />
                <div className="md:absolute top-0 min-h-[90px] md:pt-[35px] pb-0 mb-8 md:mb-0 w-full">
                  <h4 className="text-[2rem] font-light mb-1">
                    {t('labels.statistic3Title', SCOPE_OPTIONS)}
                  </h4>
                  <div>{t('labels.statistic3Description', SCOPE_OPTIONS)}</div>
                </div>
              </div>
              <div className="relative md:table-cell text-center">
                <div className="relative w-full h-1 border-b border-dotted border-[#5f4b8b] m-0 hidden md:block" />
                <div className="md:absolute top-0 min-h-[90px] md:pb-[35px] mb-8 md:mb-0 w-full md:-translate-y-full">
                  <h4 className="text-[2rem] font-light mb-1">
                    {t('labels.statistic4Title', SCOPE_OPTIONS)}
                  </h4>
                  <div>{t('labels.statistic4Description', SCOPE_OPTIONS)}</div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-wrap justify-between pt-19 pb-24">
            <div className="w-full">
              <div className="text-center">
                <h4 className="text-2xl mb-4 pb-4 font-bold blog-title inline-block text-[32px] color-primary font-poppins uppercase relative pb-3">
                  {t('labels.blog', SCOPE_OPTIONS)}
                </h4>
              </div>
              <div className="grid md:grid-cols-3 gap-8 items-start">
                {allMarkdownRemark.edges.slice(0, 3).map(({ node }) => (
                  <BlogCard
                    title={node.frontmatter.title}
                    path={node.frontmatter.path}
                    image={node.frontmatter.cover}
                    date={node.frontmatter.date}
                    key={node.frontmatter.path}
                    html={node.html}
                  />
                ))}
              </div>

              <div className="w-full shadow-boxOther p-4 mt-8 text-center font-semibold font-poppins text-[#797979]">
                <Link className="text-lg" to="/blog">
                  {t('labels.showAllBlogPosts', SCOPE_OPTIONS)}
                </Link>
              </div>
            </div>
            {/*<div className="w-full md:w-5/12 mt-24 md:mt-0">
              <h4 className="text-2xl mb-8 border-b pb-4 text-primary font-bold">
                {t('labels.contactUs', SCOPE_OPTIONS)}
              </h4>
              <form onSubmit={handleSubmit(handleFormSubmit)}>
                <div className="mb-4">
                  <span className="text-gray-500 font-bold">
                    {t('form.name.label', SCOPE_OPTIONS)}
                  </span>
                  <input
                    type="text"
                    className="block border p-3.5 w-full focus:outline-none"
                    {...register('name', { required: true })}
                  />
                  {errors?.name?.type === 'required' && (
                    <p className="text-red-600">{t('form.name.rules.required', SCOPE_OPTIONS)}</p>
                  )}
                </div>
                <div className="mb-4">
                  <span className="text-gray-500 font-bold">
                    {t('form.email.label', SCOPE_OPTIONS)}
                  </span>
                  <input
                    type="email"
                    className="block border p-3.5 w-full focus:outline-none"
                    {...register('email', { required: true })}
                  />
                  {errors?.email?.type === 'required' && (
                    <p className="text-red-600">{t('form.email.rules.required', SCOPE_OPTIONS)}</p>
                  )}
                </div>
                <div className="mb-4">
                  <span className="text-gray-500 font-bold">
                    {t('form.phone.label', SCOPE_OPTIONS)}
                  </span>
                  <input
                    type="text"
                    className="block border p-3.5 w-full focus:outline-none"
                    {...register('phone', { required: true })}
                  />
                  {errors?.email?.type === 'required' && (
                    <p className="text-red-600">{t('form.email.rules.required', SCOPE_OPTIONS)}</p>
                  )}
                </div>
                <div className="mb-4">
                  <span className="text-gray-500 font-bold">
                    {t('form.subject.label', SCOPE_OPTIONS)}
                  </span>
                  <input
                    type="text"
                    className="block border p-3.5 w-full focus:outline-none"
                    {...register('subject')}
                  />
                </div>
                <div className="mb-4">
                  <span className="text-gray-500 font-bold">
                    {t('form.message.label', SCOPE_OPTIONS)}
                  </span>
                  <textarea
                    className="block border p-3.5 w-full focus:outline-none"
                    rows={10}
                    {...register('message')}
                  />
                </div>
                <button
                  type="submit"
                  className="flex items-center text-center justify-center w-full shadow-inner border-2 bg-gray-100 h-12 py-2.5 px-5 rounded font-semibold"
                  disabled={isLoading}
                >
                  {t('form.submit', SCOPE_OPTIONS)}
                  {isLoading && <Spinner />}
                </button>
              </form>
                  </div>*/}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Home;

export const query = graphql`
  query ($language: String!) {
    allMarkdownRemark(
      filter: { frontmatter: { language: { eq: $language }, type: { eq: "blog" } } }
      sort: { fields: frontmatter___date, order: DESC }
      limit: 8
    ) {
      edges {
        node {
          id
          frontmatter {
            cover {
              publicURL
            }
            title
            path
            date(formatString: "MMMM D, YYYY", locale: $language)
          }
          html
        }
      }
    }

    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
